@use 'colors';

$border-sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
$border-styles: 'dashed', 'dotted', 'double', 'groove', 'hidden', 'inset', 'none', 'outset', 'ridge', 'solid';

.border-none {
  border: none;
}

@each $border-size in $border-sizes {
  .border-radius-#{$border-size} {
    border-radius: #{$border-size}px;
  }
}

@each $border-style in $border-styles {
  .border-#{$border-style} {
    border-style: #{$border-style} !important;
  }
}

.border-light-1 {
  @extend .border-1;
  @extend .border-gray-100;
}
.border-error-1 {
  @extend .border-1;
  @extend .border-red;
}
