@use 'palette';
@import 'variables';
// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.75rem;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: palette.$white;
$modal-content-border-color: none;
$modal-content-border-width: none;
$modal-content-border-radius: 6px;
$modal-content-inner-border-radius: 0;
$modal-content-box-shadow-xs: 0px 0px 15px rgba(0, 0, 0, 0.15);
$modal-content-box-shadow-sm-up: 0px 0px 15px rgba(0, 0, 0, 0.15);

$modal-backdrop-bg: palette.$black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-header-padding-y: 1.5rem;
$modal-header-padding-x: 1.5rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x;

$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 414px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, -10px);
$modal-show-transform: scale(1);
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1);

$zindex-modal: 10000;
$zindex-modal-backdrop: 9999;

@import 'bootstrap/scss/_modal.scss';

$bg-url: '../assets/images/one_vision_login_page_background.png';

.page-container {
  background-size: 100%;
  @extend .bg-basic-2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100vw;

  &.login {
    @include media-breakpoint-up(sm) {
      background-image: url($bg-url);
    }
  }
}

ngb-modal-window.d-block.modal.fade.show::-webkit-scrollbar {
  display: none;
}
