/* You can add global styles to this file, and also import other style files */

// @import "../node_modules/bootstrap/scss/bootstrap-grid.scss";

@import './styles/functions';
@import './styles/variables';
@import './styles/mixins';

@import './styles/reset';
@import './styles/grid';
@import './styles/position';

@import './styles/button-group';
@import './styles/buttons';
@import './styles/forms';
@import './styles/card';
@import './styles/text';
@import './styles/borders';
@import './styles/opacity';
@import './styles/overflow';

@import './styles/nav';
@import './styles/fonts';
@import './styles/shared';
@import './styles/modal';

@import './styles/drag-drop';
@import './styles/colors';
