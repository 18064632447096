@use 'palette';
@use 'colors';

.nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 2;

  color: palette.$navbar-color;
  height: $main-nav-height;
  background-color: colors.$navbar-background-color;

  border-bottom: 1px solid colors.$navbar-bottom-border;

  position: fixed;
  z-index: 1000;
  width: 100%;

  .nav-list {
    display: flex;
    align-items: center;
  }
}

.nav-left {
  display: none;
  width: $left-nav-width;
  z-index: 2;

  color: palette.$navbar-color;
  background-color: colors.$navbar-background-color;
  min-height: 100vh;

  .nav-list {
    height: 80px;
  }
}

.main-container {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  // Router outlet injected component
  & > ng-component {
    width: 100%;
  }
}

.main-container.with-main-nav {
  .main-header {
    display: flex !important;
  }
}

.main-container.with-left-nav {
  // Router outlet injected component
  & > ng-component {
    margin-left: $left-nav-width;
  }

  .nav-left {
    display: block !important;
  }

  .left-bar {
    display: flex !important;
  }
}

.nav,
.nav-left {
  .nav-list {
    margin: 0;
    padding: 0;

    .nav-item {
      text-align: center;
      list-style: none;
      user-select: none;
    }
  }
}

.nav-left {
  .nav-list {
    width: $left-nav-width;

    margin: 0;
    padding: 0;

    .nav-item {
      &.active {
        color: colors.$navbar-left-icon-color-active;
        background-color: darken(colors.$navbar-background-color, 10);

        i.icon {
          background-color: colors.$navbar-left-icon-color-active;
        }
      }

      &:not(.active):not(.disabled) {
        cursor: pointer;
        color: colors.$navbar-left-icon-color;
        background-color: colors.$navbar-background-color;

        i.icon {
          background-color: colors.$navbar-left-icon-color;
        }

        &:hover {
          background-color: colors.$navbar-left-icon-hover;
        }
      }

      &.disabled {
        cursor: default;
        color: colors.$navbar-left-icon-color;
        opacity: 0.5;
      }
    }
  }
}
