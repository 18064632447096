@use 'palette';
@use 'grid';
@use 'sass:map';
@use 'eki-ui-kit/colors';

$colors: (
  'white': palette.$white,
  'black': palette.$black,
  'green': palette.$green,
  'red': palette.$red,
  'gray': palette.$gray,
  'orange': palette.$orange,
  'basic-1': palette.$basic-1,
  'basic-2': palette.$basic-2,
  'basic-3': palette.$basic-3,
  'primary-1': palette.$primary-1,
  'primary-2': palette.$primary-2,
  'secondary-1': palette.$secondary-1,
  'secondary-2': palette.$secondary-2,
  'secondary-3': palette.$secondary-3,
  'background': palette.$background,
  'background-1': palette.$background-1,
  'success': palette.$success
);

$lighten-color-variations: (
  50: 45,
  70: 43,
  80: 42,
  100: 40,
  200: 30,
  300: 20,
  400: 10
);

$darken-color-variations: (
  600: 10,
  700: 20,
  800: 30,
  900: 40
);

$directions: (
  l: left,
  r: right,
  t: top,
  b: bottom
);

$sizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;

$colors-variations: ();

// Create colors variation like red-500, red-600, basic-1-200, green, white, ...
@each $color-name, $color in $colors {
  $color-map: ();
  $color-map: map-merge(
    $colors-variations,
    (
      '#{$color-name}': $color,
      '#{$color-name}-500': $color
    )
  );

  @each $variation, $value in $lighten-color-variations {
    $variation-map: ();

    $variation-map: map.merge(
      $colors-variations,
      (
        '#{$color-name}-#{$variation}': lighten($color, $value)
      )
    );

    $color-map: map.merge($color-map, $variation-map);
  }

  @each $variation, $value in $darken-color-variations {
    $variation-map: ();

    $variation-map: map.merge(
      $colors-variations,
      (
        '#{$color-name}-#{$variation}': darken($color, $value)
      )
    );
    $color-map: map.merge($color-map, $variation-map);
  }

  $colors-variations: map.merge($colors-variations, $color-map);
}

// bg-red, bg-primary-1-200, bg-primary-1-300, ...
// text-red, text-primary-1-200, text-primary-1-300, ...
// fill-red, fill-primary-1-200, fill-primary-1-300, ...
@each $prop, $abbrev in (background-color: bg, color: text, fill: fill, stroke: stroke) {
  @each $color-name, $color in $colors-variations {
    .#{$abbrev}-#{$color-name} {
      #{$prop}: $color;
    }
  }
}

// Borders
@each $size in $sizes {
  // border-1, border-3, ...
  .border-#{$size} {
    border-width: #{$size}px !important;
    border-style: solid;
  }

  @each $dir, $direction in $directions {
    // border-t-2, border-l-1, ...
    .border-#{$dir}-#{$size} {
      border-#{$direction}-width: #{$size}px !important;
      border-#{$direction}-style: solid;
    }
  }
}

// border-white, border-red-500, border-basic-1-200 ...
@each $color-name, $color in $colors-variations {
  .border-#{$color-name} {
    border-color: $color;
  }

  // border-t-white, border-b-red-500, border-l-basic-1-200 ...
  @each $dir, $direction in $directions {
    .border-#{$dir}-#{$color-name} {
      border-#{$direction}-color: $color;
    }
  }
}

$icon-background-color: #0004;
$modal-background-color: #0007;

// Navbar
$navbar-background-color: palette.$navbar-background-color;
$navbar-left-icon-color: palette.$navbar-left-icon-color;
$navbar-left-icon-color-active: palette.$navbar-left-icon-color-active;
$navbar-left-icon-hover: palette.$navbar-left-icon-hover;
$navbar-color: palette.$navbar-color;
$navbar-bottom-border: palette.$navbar-bottom-border;

// Scenarios

$initial-scenario-color: palette.$orange;
$optimized-scenario-color: #8097e9;
$manual-scenario-color: palette.$pink;
$draft-status-color: palette.$orange;
$archived-status-color: #c7d5e2;
$complete-status-color: #6bbc95;
$error-status-color: palette.$red;
$btn-primary-text-color: palette.$btn-primary-text-color;
$lever-label-color: #202d39;
$progress-background: #eaf7f3;
$kpi-values: #44617b;
//channel perf
$label-basic-color: #567b9c;

// Nested cards
$subtitle-text-color: #94adc4;
$lever-diffference-color-positive: #217557;
$lever-diffference-color-negative: palette.$red;
$lever-diffference-color-indefinite: palette.$orange;
$lever-diffference-background-color-negative: #ebc9c7;
$nested-level-3: #efb57b;
$nested-level-2: #ebc9c7;
$nested-level-1: #42919b;
$nested-level-0: #becfdb;
$label-scenario: #7394b1;
$icon-color: #192e43;

// loader create :
$background-loader: #dbe5ef;
