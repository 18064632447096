// https://getbootstrap.com/docs/4.5/components/buttons/
// See bootstrap impl in  file://./../../node_modules/bootstrap/scss/utilities/_text.scss

$font-weigths: 100, 200, 300, 400, 500, 600, 700, 800, 900;

@each $weigth in $font-weigths {
  .bold-#{$weigth} {
    font-weight: #{$weigth};
  }
}

$line-heigths: (
  3: 0.75rem,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  7: 1.75rem,
  8: 2rem,
  9: 2.25rem,
  10: 2.5rem,
  none: 1,
  tigth: 1.25,
  snug: 1.375,
  normal: 1.5,
  relaxed: 1.625,
  loose: 2
);

@each $abbrev, $size in $line-heigths {
  .leading-#{$abbrev} {
    line-height: #{$size};
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left {
      text-align: left !important;
    }
    .text#{$infix}-right {
      text-align: right !important;
    }
    .text#{$infix}-center {
      text-align: center !important;
    }
  }
}

$letter-spacings: 0, 1, 2, 3, 4, 5;

@each $letter-spacing in $letter-spacings {
  .letter-#{$letter-spacing} {
    letter-spacing: #{$letter-spacing}px;
  }
}

.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
