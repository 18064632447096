// https://getbootstrap.com/docs/4.5/components/card/
// See bootstrap impl in  file://./../../node_modules/bootstrap/scss/_card.scss

@use 'palette';

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  word-wrap: break-word;
  background-color: palette.$card-bg;

  @include border-radius($card-border-radius);

  .card-head {
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 0;
    color: $card-header-color;
    background-color: palette.$card-header-bg;
    border-bottom: $card-border-width solid $card-border-color;
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  .card-body {
    flex: 1 1 auto;
    padding: $card-spacer-y $card-spacer-x;
  }
}
