@use 'colors';
@use 'palette';

// See bootstrap impl in  file://./../../node_modules/bootstrap/scss/_forms.scss
// and                    file://./../../node_modules/bootstrap/scss/_input-group.scss

.form-group {
  margin-bottom: $form-group-margin-bottom;
}

.form-control {
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
}

.form-control-default {
  width: 190px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  &.outlined {
    &:not(.disabled):hover {
      & > .form-control {
        @extend .border-basic-2;
      }

      & > .input-group-prepend,
      & > .input-group-append {
        .input-group-icon {
          @extend .border-basic-2;

          & > .icon {
            @extend .bg-basic-2;
          }
        }
      }
    }

    %form-control {
      @extend .border-1;
      @extend .border-basic-2-200;
      border-radius: $input-border-radius;
    }

    & > .form-control {
      @extend %form-control;
    }

    & > .input-group-prepend,
    & > .input-group-append {
      .input-group-button,
      .input-group-text,
      .input-group-icon {
        @extend %form-control;
      }
    }

    & > .input-group-prepend {
      & > .input-group-button,
      & > .input-group-text,
      & > .input-group-icon {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    & > .input-group-append {
      & > .input-group-button,
      & > .input-group-text,
      & > .input-group-icon {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &.flat {
    &:hover {
      & > .form-control {
        @extend .border-basic-2;
      }

      & > .input-group-prepend + & > .input-group-append {
        .input-group-icon {
          @extend .border-basic-2;

          & > .icon {
            @extend .bg-basic-2;
          }
        }
      }
    }

    %form-control {
      border: unset;
      @extend .border-b-1;
      @extend .border-b-basic-2-200;
    }

    & > .form-control {
      @extend %form-control;
    }

    & > .input-group-prepend,
    & > .input-group-append {
      .input-group-button,
      .input-group-text,
      .input-group-icon {
        @extend %form-control;
      }
    }
  }

  & > .form-control {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .input-group-button,
  .input-group-text,
  .input-group-icon {
    display: flex;
    align-items: center;
    padding: 0 5px;
    text-align: center;
    white-space: nowrap;
    justify-content: center;
    i.icon {
      background-color: rgba(palette.$input-color, $input-icon-opacity);
    }
  }
}

.input-group-prepend {
  margin-right: -1px;
  display: flex;
}

.input-group-append {
  margin-left: -1px;
  display: flex;
}

textarea.form-control {
  height: auto;
  resize: vertical;
  min-height: $input-height;
}

.ng-invalid.ng-dirty {
  & > .input-group {
    & > .form-control:not([type='checkbox']),
    .input-group-text,
    .input-group-button {
      @extend .border-red;

      i.icon {
        background-color: rgba(palette.$red, $input-icon-opacity);
      }
    }

    & > .input-group-prepend {
      & > .input-group-icon {
        @extend .border-red;

        i.icon {
          background-color: rgba(palette.$red, $input-icon-opacity);
        }
      }
    }
  }
}

.input-group.focused {
  & > .form-control:not([type='checkbox']),
  .input-group-button,
  .input-group-text,
  & > .input-group-prepend .input-group-icon,
  & > .input-group-append .input-group-icon {
    @extend .border-basic-1;

    i.icon {
      background-color: rgba(palette.$basic-1, $input-icon-opacity);
    }
  }
}

.input-group.disabled {
  @extend .bg-white-200;
  & > .form-control:not([type='checkbox']),
  .input-group-button,
  .input-group-text,
  .input-group-icon {
    @extend .border-gray-200;
    @extend .text-gray-200;

    i.icon {
      @extend .bg-gray-200;
    }
  }
}

label {
  display: inline-block;
  margin-bottom: 6px;
}
