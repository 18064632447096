// https://getbootstrap.com/docs/4.5/components/buttons/
// @import "../../node_modules/bootstrap/scss/_buttons";

@use 'palette';
@use 'colors';

// See bootstrap impl in  file://./../../node_modules/bootstrap/scss/_buttons.scss

$btn-colors: (
  'basic': (
    color: palette.$white,
    border: palette.$basic-2,
    background: palette.$basic-2,
    active: palette.$basic-2
  ),
  'basic-3': (
    color: palette.$white,
    border: darken(palette.$basic-3, 40),
    background: darken(palette.$basic-3, 40),
    active: darken(palette.$basic-3, 40)
  ),
  'primary-1': (
    color: palette.$btn-primary-text-color,
    border: palette.$primary-1,
    background: palette.$primary-1,
    active: palette.$primary-1
  ),
  'secondary-1': (
    color: palette.$basic-2,
    border: palette.$secondary-1,
    background: palette.$secondary-1,
    active: palette.$secondary-1
  ),
  'secondary-3': (
    color: palette.$basic-2,
    border: darken(palette.$secondary-3, 10),
    background: palette.$secondary-3,
    active: palette.$secondary-3
  ),
  'primary-light': (
    color: darken(palette.$primary, 40),
    border: lighten(palette.$background, 35),
    background: lighten(palette.$background, 35),
    active: lighten(palette.$background, 35)
  ),
  'primary': (
    color: palette.$black,
    border: lighten(palette.$primary, 40),
    background: lighten(palette.$primary, 40),
    active: lighten(palette.$primary, 40)
  ),
  'light': (
    color: palette.$basic-2,
    border: lighten(palette.$background-1, 0),
    background: lighten(palette.$background-1, 0),
    active: palette.$secondary-1
  ),
  'red': (
    color: palette.$basic-2,
    border: palette.$red,
    background: lighten(palette.$red, 30)
  ),
  'dark-red': (
    color: palette.$basic-2,
    border: darken(palette.$red, 10),
    background: darken(palette.$red, 10)
  ),
  'white': (
    color: palette.$basic-2,
    border: darken(palette.$background-1, 10),
    background: palette.$white
  ),
  'special': (
    color: palette.$navbar-color,
    border: palette.$navbar-color,
    background: palette.$navbar-color,
    active: palette.$navbar-color
  ),
  'alert': (
    color: palette.$white,
    border: palette.$red,
    background: palette.$red,
    active: darken(palette.$red, 10)
  )
);

@each $color, $map in $btn-colors {
  $btn-color: map-get($map, color);
  $btn-border-color: map-get($map, border);
  $btn-background-color: map-get($map, background);
  $btn-active-color: map-get($map, active);

  .btn.btn-#{$color} {
    border: $btn-border-width solid $btn-border-color;

    // Color contrast
    color: $btn-color;
    background-color: $btn-background-color;

    &.btn-icon > i.icon {
      background-color: $btn-color;
    }

    &.btn-outline {
      color: $btn-background-color;
      background-color: transparent;

      &.btn-icon > i.icon {
        background-color: $btn-background-color;
      }
    }

    &.btn-square {
      color: $btn-color;
      background-color: $btn-background-color;
      border-radius: 0px !important;

      &.btn-icon > i.icon {
        background-color: $btn-color;
      }
    }

    &.btn-round {
      color: $btn-color;
      background-color: $btn-background-color;
      border-radius: 50%;

      &.btn-icon > i.icon {
        background-color: $btn-color;
      }
    }

    &:not(:disabled):not(.disabled) {
      &:hover,
      &.hover {
        text-decoration: none;
        border-color: darken($btn-border-color, 10);
        background-color: darken($btn-background-color, 10);

        &.btn-outline {
          background-color: transparent;
          color: darken($btn-background-color, 10);

          & > i.icon {
            background-color: darken($btn-background-color, 10);
          }
        }

        &.btn-special {
          border-color: palette.$secondary-1;
          background-color: transparent;
          color: palette.$secondary-1;

          &.btn-icon > i.icon {
            @extend .bg-secondary-1;
          }
        }
      }

      &:active,
      &.active {
        background-color: $btn-active-color;
      }
    }
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  transition: $btn-transition;
  white-space: nowrap;

  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

  &.btn-icon {
    @include button-size($btn-padding-y, $btn-padding-y, $btn-font-size, $btn-line-height, $btn-border-radius);

    &.btn-icon-with-text {
      @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    }
  }

  &.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);

    &.btn-icon {
      @include button-size($btn-padding-y-lg, $btn-padding-y-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);

      &.btn-icon-with-text {
        @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
      }
    }
  }

  &.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);

    &.btn-icon {
      @include button-size($btn-padding-y-sm, $btn-padding-y-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);

      &.btn-icon-with-text {
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
      }
    }
  }

  &.btn-fill {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @include transition($btn-transition);

  &.btn-icon {
    > i.icon {
      width: 15px;
      height: 15px;
      mask-size: 100% 100%;
      vertical-align: middle;
    }

    &.btn-icon-with-text > i.icon {
      margin-right: 7px;
    }
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;

    @include box-shadow($btn-active-box-shadow);
  }
}

.img-button {
  height: 40px;
  @extend .bg-background-1;
  border-radius: 3px;
  border: none;
  border-bottom: 4px solid transparent;
  cursor: pointer;

  &:hover {
    @extend .bg-background-1-600;
  }
}
