$overflows: auto, hidden, clip, visible, scroll, overlay, revert, unset;

@each $overflow in $overflows {
  .overflow-#{$overflow} {
    overflow: $overflow;
  }

  .overflow-x-#{$overflow} {
    overflow-x: $overflow;
  }

  .overflow-y-#{$overflow} {
    overflow-y: $overflow;
  }
}
