@use 'colors';

.dndPlaceholder {
  overflow: hidden;
  display: block;
  min-height: 1px;
  @extend .border-1;
  @extend .border-green;
  border-style: dashed;
  @extend .bg-gray-100;
  margin-bottom: 15px;
  max-width: 100%;
}
