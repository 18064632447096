// Import bootstrap functions...
@import 'bootstrap/scss/functions';

// We can add some more here!

$rotations: 45, 90, 180;

@each $rotation in $rotations {
  .rotate-#{$rotation} {
    transform: rotate(#{$rotation}deg);
  }
}
