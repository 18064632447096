// You can override bootstrap default variables here

// Import bootstrap variables
// @import "../../node_modules/bootstrap/scss/_variables";
@use 'palette';
@import 'functions';

//
// COLORS
//

// Short aliases
// $primary :$basic-1;
// $secondary: colors.$basic-2/;
// $yellow :$primary-1;
// $blue :$secondary-1;

$fs-unit: 'px';

// Options line 100
$enable-shadows: false;
$enable-rounded: true;
$enable-print-styles: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: false;

$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;

//
// DESIGN SYSTEM FONTS
//

// TITLES
$font-size-main-title: 17px;
$font-size-h1: 35px;
$font-size-h2: 25px;
$font-size-h3: 20px;
$font-size-h4: 18px;
$font-size-h5: 15px;

// TEXT
$font-size-1: 22px;
$font-size-2: 18px;
$font-size-3: 14px;
$font-size-4: 12px;
$font-size-5: 10px;
$font-size-6: 8px;

//
// Line 240
// COMPONENTS (COMMON DEFINITIONS)
//
$border-width: 1px;
$border-radius: 6px;
$box-shadow: 0 0.5rem 1rem rgba(palette.$black, 0.15);

$font-size-sm: 11px;
$font-size-base: 13px;
$font-size-lg: 13px;

$line-height-sm: 1;
$line-height-base: 1.4;
$line-height-lg: 1.4;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: 400;
$font-weight-normal: $font-weight-base;
$headings-font-weight: $font-weight-normal;

//
// Line 450
// FORMS
//

// Buttons + Forms line 380
// $input-disabled-bg: #f3f3f3;
// $input-border-color: colors.$basic-2-400;
// $input-hover-border-color: colors.$basic-2-700;
// $input-focus-border-color: colors.$basic-1;
$input-disabled-border-color: #bbc4cc;
$input-btn-focus-box-shadow: none;
$form-group-margin-bottom: 1rem;

$input-icon-opacity: 0.7;
$input-border-radius: $border-radius;
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: $line-height-base;
$input-border-width: $border-width;
$input-height-border: $input-border-width * 2;
$input-height: add($input-line-height * 1em, 1em);

//// Disable outline
$input-btn-focus-width: 0;

//
// Line 830
// CARDS
//

$card-spacer-x: 1.25rem;
$card-spacer-y: 1.5rem;
$card-border-radius: 8px;
$card-border-width: 0;
$card-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
$card-color: null;
$card-border-color: null;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-header-color: null;

//
// NAV
//

$main-nav-height: 80px;
$left-nav-width: 90px;

//
// BUTTONS
//

$btn-disabled-opacity: 0.3;

$btn-font-family: null;
$btn-font-weight: null;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
$btn-border-width: 1px;
$btn-active-box-shadow: inset 0 3px 5px rgba(palette.$black, 0.125);
$toggle-size: 10px;
$toggle-spacing: 3px;

// Button small
$btn-padding-x-sm: 14px;
$btn-padding-y-sm: 6px;
$btn-font-size-sm: $font-size-sm;
$btn-line-height-sm: 12.65px;
$btn-border-radius-sm: 4px;

// Button normal
$btn-padding-x: 20px;
$btn-padding-y: 10px;
$btn-font-size: $font-size-base;
$btn-line-height: 14.95px;
$btn-border-radius: 6px;

// Button large
$btn-padding-x-lg: 36px;
$btn-padding-y-lg: 14px;
$btn-font-size-lg: $font-size-lg;
$btn-line-height-lg: 14.95px;
$btn-border-radius-lg: 8px;

//
// TABLES
//

// $table-2-border-color: colors.$gray-200;

$table2-cell-height: 44px;
$table2-cell-padding-x: 12px;
$table2-cell-padding-y: 10px;

$table3-header-cell-height: 42px;
$table3-cell-height: 64px;
$table3-cell-padding-x: 24px;

// transitions
$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

// positions
$positions: static, relative, absolute, fixed, sticky !default;

//
// TAGS
//

$border-radius-tag: 4px;

// Spacing
$spacer: 1rem;

$spacer-1: $spacer * 0.25;
$spacer-2: $spacer * 0.5;
$spacer-3: $spacer;
$spacer-4: $spacer * 1.5;
$spacer-5: $spacer * 3;
$spacer-6: $spacer * 4.5;
$spacer-7: $spacer * 6;
$spacer-8: $spacer * 7.5;

// BREAKPOINT
$breakpoint-1: 1250px;

// Header
$sticky-header-height: 74px;
