$black: #000;
$white: #fff;
$gray: #808080;
$basic-1: #344256;
$basic-2: #32475a;
$basic-3: #6394c5;
$primary: #94adc4;
$primary-1: #007cba;
$primary-2: #7ec6ea;
$secondary-1: #b9d6e4;
$secondary-2: #84bfdd;
$secondary-3: #e7f4ff;
$orange: #e9a85d;
$red: #e94966;
$pink: #c982eb;
$green: #1b6137;
$background: #5d89b5;
$background-1: #f4f6f8;
$success: #2c9d82;

//
// COMPONENT COLORS
//

// buttons

$navbar-background-color: white;
$navbar-left-icon-color: $basic-1;
$navbar-left-icon-color-active: $basic-1;
$navbar-left-icon-hover: darken($navbar-background-color, 10);
$navbar-color: $basic-1;
$navbar-bottom-border: #e3e5e8;

$input-color: darken($gray, 10);
$card-bg: $white;
$card-header-bg: lighten($secondary-1, 10);

$btn-primary-text-color: white;

$login-text-color: white;

$primary-300: #c7d5e2;
$primary-600: #567b9c;
$primary-900: #192e43;
$background-80: #f0f4f8;
$background-140: #dbe5ef;
