// Same as in file://./../../node_modules/bootstrap/scss/bootstrap-reboot.scss

@use 'palette';
@use 'sass:math';

//// Pulled by main scss
// @import "functions";
// @import "variables";
// @import "mixins";

$spacer: 1rem;
$dt-font-weight: $font-weight-bold;
$font-weight-bolder: $font-weight-bolder;
$headings-margin-bottom: math.div($spacer, 2);

$link-color: palette.$basic-1;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Style p element.
$paragraph-margin-bottom: 1rem;

// Table element.
$table-cell-padding: 0.75rem;
$table-caption-color: darken(palette.$gray, 5);
$table-th-font-weight: null;

// Label element
$label-margin-bottom: 0.5rem;
$enable-pointer-cursor-for-buttons: false; // We handle this ourselves

:focus {
  outline: 0;
}

hr {
  border: 0;
  height: 1px;
  margin: 1rem;
  @extend .bg-gray-400;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// See https://getbootstrap.com/docs/4.5/content/reboot/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(palette.$black, 0);
}

body {
  margin: 0;
  @include font-size($font-size-base);
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  @extend .text-gray-900;
  text-align: left;
  @extend .bg-background-100;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  @include font-size(inherit);
  line-height: inherit;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

ul {
  margin-top: 0;
  margin-bottom: $spacer-3;
}
