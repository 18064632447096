@use 'colors';

@font-face {
  font-family: Tiempos-Ekimetrics;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: local('Tiempos Ekimetrics'), local('Tiempos-Ekimetrics'), url(./../assets/fonts/TiemposHeadlineWeb-Black.woff) format('woff'),
    url(./../assets/fonts/TiemposHeadlineWeb-Black.eot) format('embedded-opentype');
}

@font-face {
  font-family: Tiempos-Ekimetrics;
  font-style: normal;
  font-weight: $font-weight-normal;
  src: local('Tiempos Ekimetrics'), local('Tiempos-Ekimetrics'), url(./../assets/fonts/TiemposHeadline-Medium.woff) format('woff');
}

@font-face {
  font-family: Tiempos-Ekimetrics;
  font-style: normal;
  font-weight: $font-weight-light;
  src: local('Tiempos Ekimetrics'), local('Tiempos-Ekimetrics'), url(./../assets/fonts/TiemposHeadline-Light.woff) format('woff');
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/open-sans-v18-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/open-sans-v18-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('../assets/fonts/open-sans-v18-latin-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('../assets/fonts/open-sans-v18-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

h1 {
  font-family: Tiempos-Ekimetrics;
  font-style: normal;
  font-weight: 900;
}

h2,
h3,
h4,
h5 {
  font-style: normal;
  font-weight: 700;
}

// See bootstrap font-size function in file://./../../node_modules/bootstrap/scss/vendor/_rfs.scss

.font-tiempos {
  font-family: Tiempos-Ekimetrics !important;
}

.main-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;

  /* @include font-size($font-size-main-title); */
  font-size: $font-size-main-title;

  text-transform: uppercase;
}

h1 {
  /* @include font-size($font-size-h1); */
  font-size: $font-size-h1;
}

h2 {
  /* @include font-size($font-size-h2); */
  font-size: $font-size-h2;
}

h3 {
  /* @include font-size($font-size-h3); */
  font-size: $font-size-h3;
}

h4 {
  /* @include font-size($font-size-h4); */
  font-size: $font-size-h4;
}

h5 {
  /* @include font-size($font-size-h5); */
  font-size: $font-size-h5;
}

.text-1 {
  /* @include font-size($font-size-1); */
  font-size: $font-size-1;
}

.text-2 {
  /* @include font-size($font-size-2); */
  font-size: $font-size-2;
}

.text-3 {
  /* @include font-size($font-size-3); */
  font-size: $font-size-3;
}

.text-4 {
  /* @include font-size($font-size-4); */
  font-size: $font-size-4;
}

.text-2 {
  /* @include font-size($font-size-5); */
  font-size: $font-size-5;
}

.text-6 {
  /* @include font-size($font-size-5); */
  font-size: $font-size-6;
}

strong,
.strong {
  font-weight: 700;
}

// Default font
body {
  @extend .text-basic-1;
  font-weight: 400;
  font-size: $font-size-base;
}

// Force default font style
.text-normal {
  font-weight: 400 !important;
}

.font-weight-lighter {
  font-weight: $font-weight-lighter;
}

.font-weight-light {
  font-weight: $font-weight-light;
}

.font-weight-normal {
  font-weight: $font-weight-normal;
}

.font-weight-bold {
  font-weight: $font-weight-bold;
}

.font-weight-bolder {
  font-weight: $font-weight-bolder;
}
