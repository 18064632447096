@use 'colors';
@use 'palette';
@use 'variables';
@use 'sass:math';

// $colors: (
//   'gray': colors.$white,
//   'basic-1': colors.$white,
//   'basic-2': colors.$white,
//   'secondary-1': colors.$white,
//   'secondary-2': colors.$white,
//   'secondary-3': colors.$white,
//   'primary-1': colors.$white,
//   'primary-2': colors.$white
// );

// @each $color, $value in $colors {
//   .bg-#{$color} { background-color: $value; }
//   .text-#{$color} { color: $value; }
// }

$scenario-colors: (
  'initial': colors.$initial-scenario-color,
  'optimized': colors.$optimized-scenario-color,
  'manual': colors.$manual-scenario-color
);

@each $scenario, $color in $scenario-colors {
  .bg-scenario-#{$scenario} {
    @extend .text-white;
    background-color: $color;
  }

  .text-scenario-#{$scenario} {
    color: $color;
  }
}

$status-colors: (
  'draft': colors.$draft-status-color,
  'ongoing': colors.$draft-status-color,
  'complete': colors.$complete-status-color,
  'done': colors.$complete-status-color,
  'error': colors.$error-status-color,
  'failed': colors.$error-status-color,
  'archived': colors.$archived-status-color
);

@each $status, $color in $status-colors {
  .bg-status-#{$status} {
    @extend .text-white;
    background-color: $color;
  }

  .text-status-#{$status} {
    color: $color;
  }
}

// For now we don't need all the above combinations
.bg-secondary {
  @extend .bg-basic-2;
}

.bg-light {
  @extend .bg-background-1;
}

.text-primary {
  @extend .text-primary-1;
}

.text-white {
  @extend .text-white;
}

.text-error {
  @extend .text-red;
}

.text-warning {
  @extend .text-orange;
}

.text-dark {
  @extend .text-basic-2-300;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@each $breakpoint, $value in $grid-breakpoints {
  // md is in position 2
  $index: index($grid-breakpoints, $breakpoint $value);
  $step: 0.2;
  $scale: (1 - $step)+ (math.div($index - 2, math.div(1, $step))); // 1 for md

  .text-#{$breakpoint} {
    font-size: variables.$font-size-base * $scale;
  }
}

// disabled add scenario tooltip
.custom-tooltip.tooltip.fade {
  margin-right: 15px;
  @extend .bg-basic-2;
  @extend .text-white;
  padding: 5px;
  @extend .border-1;
  @extend .border-basic-2;
  border-radius: 5px;
}

i.icon {
  mask-size: 90% 90%;
  mask-position: center;
  display: inline-block;
  mask-repeat: no-repeat;
  vertical-align: middle;
  background-color: rgba(palette.$black, 0.56);

  // Defaults, may be overwritten by icon directive
  width: 24px;
  height: 24px;
}

.link,
.clickable {
  cursor: pointer;
}

.link-decoration {
  text-decoration: underline;
}

.icon.icon-link {
  @extend .bg-basic-2-300;
}

$sticker-colors: (
  'gray': (
    background: palette.$background-1,
    color: darken(palette.$gray, 10)
  ),
  'yellow': (
    background: palette.$primary-1,
    color: palette.$white
  )
);

@each $color, $map in $sticker-colors {
  $sticker-background-color: map-get($map, background);
  $sticker-color: map-get($map, color);

  .sticker-#{$color} {
    background-color: $sticker-background-color;
    color: $sticker-color;
    border-radius: variables.$border-radius-tag;
    font-size: variables.$font-size-base;
    align-self: baseline;
    padding: 0.2rem 0.4rem;
  }
}

.nowrap {
  white-space: nowrap;
}

.prewrap {
  white-space: pre-wrap;
}

.title-description {
  @extend .text-basic-2-300;
}

.section-description {
  @extend .text-basic-2-300;
}

.color-primary {
  @extend .text-basic-1;
}

.color-secondary {
  @extend .text-basic-2-300;
}

.rounded {
  border-radius: 50%;
}

.scenario-type-chip {
  display: inline-block;
  text-align: center;
  width: 160px;
  height: 29px;
  line-height: 30px;
  border-radius: 15px;
  text-transform: capitalize;
}

$tag-colors: (
  'gray': (
    background: lighten(palette.$gray, 30),
    color: palette.$white
  ),
  'yellow': (
    background: palette.$primary-1,
    color: palette.$white
  ),
  'white': (
    background: palette.$white,
    color: darken(palette.$basic-2, 5)
  ),
  'blue': (
    background: palette.$primary-1,
    color: palette.$white
  ),
  'red': (
    background: palette.$red,
    color: palette.$white
  )
);

.info-box {
  @extend .bg-orange-500;
  padding: 4px 10px;
  border-radius: 6px;
  height: 32px;
  @extend .text-white;

  i.icon {
    @extend .text-black;
    @extend .bg-white;
  }
}

@each $color, $map in $tag-colors {
  $tag-background-color: map-get($map, background);
  $tag-color: map-get($map, color);

  .tag-#{$color} {
    background-color: $tag-background-color;
    color: $tag-color;
    border-radius: variables.$border-radius-tag;
    font-size: variables.$font-size-base;
    line-height: variables.$font-size-base;
    font-weight: variables.$font-weight-bold;
    align-self: baseline;
    padding: 0.5rem;
  }
}

$label-colors: (
  'positive': (
    color: lighten(palette.$green, 20),
    background: rgba(49, 177, 100, 0.12)
  ),
  'neutral': (
    color: darken(palette.$basic-2, 10),
    background: #ecf1f5
  ),
  'negative': (
    color: palette.$red,
    background: #ffe2e7
  )
);

@each $label-color, $map in $label-colors {
  $color: map-get($map, color);
  $background-color: map-get($map, background);

  .#{$label-color}-value,
  .#{$label-color}-percentage {
    color: $color;
  }

  .background-#{$label-color}-value {
    background-color: $background-color;
  }
}

.sticky-bar {
  @extend .bg-background-1;
  top: 64px;
  z-index: 1;
}

%card-250 {
  @extend .min-width-250;
  @extend .flex-grow-1;
}

.small-card-250 {
  @extend %card-250;
  @extend .flex-basis-20;
}

.medium-card-250 {
  @extend %card-250;
  @extend .flex-basis-25;
}

.large-card-250 {
  @extend %card-250;
  @extend .flex-basis-30;
}

%card-600 {
  @extend .min-width-600;
  @extend .flex-grow-1;
}

.small-card-600 {
  @extend %card-600;
  @extend .flex-basis-50;
}

.large-card-600 {
  @extend %card-600;
  @extend .flex-basis-70;
}

.medium-card-600 {
  @extend %card-600;
  @extend .flex-basis-65;
}

.charts-container {
  @extend .d-flex;
  @extend .flex-row;
  @extend .flex-wrap;
  @extend .gap-4-4;
}

.horizontal-line {
  @extend .my-3, .mx-auto;
  @extend .w-100;
  @extend .border-b-1;
  @extend .border-b-gray-200;
}

.flex-1 {
  flex: 1;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.transparent-modal {
  background-color: rgba(palette.$primary-900, 0.5);
  backdrop-filter: blur(10px);

  .modal-dialog {
    min-width: variables.$breakpoint-1;
    max-width: 90vw;
  }

  .modal-content {
    background-color: inherit;
  }
}

.text-loader {
  color: palette.$primary-600;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
}

ngb-tooltip-window.tooltip {
  z-index: 2000;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 30px 0 0 15px;
}

.text-subtitle {
  color: colors.$subtitle-text-color;
}

.vertical-line {
  @extend .border-l-1;
  @extend .border-l-basic-2-700;
  height: variables.$input-height;
}

.infos .card {
  padding: 0;
  box-shadow: variables.$card-shadow;
}

ngb-datepicker {
  .ngb-dp-arrow-btn {
    @extend .text-primary-700;
  }
}
