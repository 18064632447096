@import 'variables';

$variations: (
  0: 0px,
  0\.5: 0.125rem,
  1: 0.25rem,
  1\.5: 0.375rem,
  2: 0.5rem,
  2\.5: 0.625rem,
  3: 0.75rem,
  3\.5: 0.875,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  7: 1.75rem,
  8: 2rem,
  9: 2.25rem,
  10: 2.5rem,
  11: 2.75rem,
  12: 3rem,
  14: 3.5rem,
  16: 4rem,
  20: 5rem,
  24: 6rem,
  28: 7rem,
  32: 8rem,
  36: 9rem,
  40: 10rem
);

$zindexes: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, auto;

$utilities: top, right, bottom, left;

@each $key, $value in $variations {
  .inset-#{$key} {
    top: #{$value};
    right: #{$value};
    bottom: #{$value};
    left: #{$value};
  }
  .inset-x-#{$key} {
    right: #{$value};
    left: #{$value};
  }
  .inset-y-#{$key} {
    top: #{$value};
    bottom: #{$value};
  }

  @each $utility in $utilities {
    .#{$utility}-#{$key} {
      #{$utility}: $value;
    }
  }
}

@each $zindex in $zindexes {
  .z-index-#{$zindex} {
    z-index: $zindex;
  }
}

@each $position in $positions {
  .position-#{$position} {
    position: $position !important;
  }
}
