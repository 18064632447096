@use 'variables';
@use 'sass:math';

// Same as in file://./../../node_modules/bootstrap/scss/bootstrap-grid.scss

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 15px;
$grid-row-columns: 6;

$grid-utilities: (
  col-start: grid-column-start,
  col-end: grid-column-end,
  row-start: grid-row-start,
  row-end: grid-row-end
);

$grid-spaces: 1, 2, 3, 4, 5, 6, 7, 8, 9;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1300px
);

$directions: (
  l: left,
  r: right,
  t: top,
  b: bottom
);

$ratio-sizes: (
  '1\\/2': 50%,
  '1\\/3': 33.333333%,
  '2\\/3': 66.666667%,
  '1\\/4': 25%,
  '2\\/4': 50%,
  '3\\/4': 75%,
  '1\\/5': 20%,
  '2\\/5': 40%,
  '3\\/5': 60%,
  '4\\/5': 80%,
  full: 100%
);

$container-max-widths: (
  sm: 710px,
  md: 960px,
  lg: 1300px,
  xl: 1480px
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacers: (
  0: 0,
  1: variables.$spacer-1,
  2: variables.$spacer-2,
  3: variables.$spacer-3,
  4: variables.$spacer-4,
  5: variables.$spacer-5,
  6: variables.$spacer-6,
  7: variables.$spacer-7,
  8: variables.$spacer-8
);

// Margin and Padding

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: math.div($gutter, 2);
  padding-left: math.div($gutter, 2);
  margin-right: auto;
  margin-left: auto;
}

// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

// Single container class with breakpoint max-widths
.container,
  // 100% wide container at all breakpoints
  .container-fluid {
  @include make-container();
}

@each $prop, $abbrev in $grid-utilities {
  @each $space in $grid-spaces {
    .#{$abbrev}-#{$space} {
      #{$prop}: $space;
    }
  }
}

@each $space in $grid-spaces {
  .grid-rows-#{$space} {
    grid-template-rows: repeat(#{$space}, minmax(0, 1fr));
  }

  .grid-cols-#{$space} {
    grid-template-columns: repeat(#{$space}, minmax(0, 1fr));
  }

  .row-span-#{$space} {
    grid-row: span #{$space} / span #{$space};
  }
}

// Responsive containers that are 100% wide until a breakpoint
@each $breakpoint, $container-max-width in $container-max-widths {
  .container-#{$breakpoint} {
    @extend .container-fluid;
  }

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }

    // Extend each breakpoint which is smaller or equal to the current breakpoint
    $extend-breakpoint: true;

    @each $name, $width in $grid-breakpoints {
      @if ($extend-breakpoint) {
        .container#{breakpoint-infix($name, $grid-breakpoints)} {
          @extend %responsive-container-#{$breakpoint};
        }

        // Once the current breakpoint is reached, stop extending
        @if ($breakpoint == $name) {
          $extend-breakpoint: false;
        }
      }
    }
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    5: 5%,
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    55: 55%,
    60: 60%,
    65: 65%,
    70: 70%,
    75: 75%,
    80: 80%,
    85: 85%,
    90: 90%,
    95: 95%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// .min-w-25 .min-h-25 .max-w-25 ...
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .min-#{$abbrev}-#{$size} {
      min-#{$prop}: $length !important;
    }
    .max-#{$abbrev}-#{$size} {
      max-#{$prop}: $length !important;
    }
  }
}

// .flex-basis-20 .flex-basis-30 .flex-basis-50 .flex-basis-75 ...

@each $size, $length in map-merge($map1: $sizes, $map2: $ratio-sizes) {
  .flex-basis-#{$size} {
    flex-basis: $length;
  }
}

// .gap-0-0 .gap-0-1 .gap-0-2 .gap-0-3 .gap-0-4 .gap-0-5
// .gap-1-0 .gap-1-1 .gap-1-2 .gap-1-3 .gap-1-4 .gap-1-5
// .gap-2-0 .gap-2-1 .gap-2-2 .gap-2-3 .gap-2-4 .gap-2-5
// .gap-3-0 .gap-3-1 .gap-3-2 .gap-3-3 .gap-3-4 .gap-3-5
// .gap-4-0 .gap-4-1 .gap-4-2 .gap-4-3 .gap-4-4 .gap-4-5
// .gap-5-0 .gap-5-1 .gap-5-2 .gap-5-3 .gap-5-4 .gap-5-5

@each $sizeH, $lengthH in $spacers {
  @each $sizeW, $lengthW in $spacers {
    .gap-#{$sizeH}-#{$sizeW} {
      gap: $lengthH $lengthW !important;
    }
  }
}

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
}

// Viewport additional helpers

.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}

//
//  DISPLAY
//

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex, grid;

// .d-inline .d-flex .d-block .d-none ...
// .d-sm-inline .d-sm-flex .d-sm-block .d-sm-none ...
// https://getbootstrap.com/docs/4.5/utilities/display/
// See bootstrap impl in  file://./../../node_modules/bootstrap/scss/utilities/_display.scss
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $value in $displays {
      .d#{$infix}-#{$value} {
        display: $value !important;
      }
    }
  }
}

// https://getbootstrap.com/docs/4.5/utilities/flex/
// See bootstrap impl in  file://./../../node_modules/bootstrap/scss/utilities/_flex.scss
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex#{$infix}-row {
      flex-direction: row !important;
    }
    .flex#{$infix}-column {
      flex-direction: column !important;
    }
    .flex#{$infix}-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex#{$infix}-column-reverse {
      flex-direction: column-reverse !important;
    }

    .flex#{$infix}-wrap {
      flex-wrap: wrap !important;
    }
    .flex#{$infix}-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex#{$infix}-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .flex#{$infix}-fill {
      flex: 1 1 auto !important;
    }
    .flex#{$infix}-grow-0 {
      flex-grow: 0 !important;
    }
    .flex#{$infix}-grow-1 {
      flex-grow: 1 !important;
    }
    .flex#{$infix}-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex#{$infix}-shrink-1 {
      flex-shrink: 1 !important;
    }

    .justify-content#{$infix}-start {
      justify-content: flex-start !important;
    }
    .justify-content#{$infix}-end {
      justify-content: flex-end !important;
    }
    .justify-content#{$infix}-center {
      justify-content: center !important;
    }
    .justify-content#{$infix}-between {
      justify-content: space-between !important;
    }
    .justify-content#{$infix}-around {
      justify-content: space-around !important;
    }

    .align-items#{$infix}-start {
      align-items: flex-start !important;
    }
    .align-items#{$infix}-end {
      align-items: flex-end !important;
    }
    .align-items#{$infix}-center {
      align-items: center !important;
    }
    .align-items#{$infix}-baseline {
      align-items: baseline !important;
    }
    .align-items#{$infix}-stretch {
      align-items: stretch !important;
    }

    .align-content#{$infix}-start {
      align-content: flex-start !important;
    }
    .align-content#{$infix}-end {
      align-content: flex-end !important;
    }
    .align-content#{$infix}-center {
      align-content: center !important;
    }
    .align-content#{$infix}-between {
      align-content: space-between !important;
    }
    .align-content#{$infix}-around {
      align-content: space-around !important;
    }
    .align-content#{$infix}-stretch {
      align-content: stretch !important;
    }

    .align-self#{$infix}-auto {
      align-self: auto !important;
    }
    .align-self#{$infix}-start {
      align-self: flex-start !important;
    }
    .align-self#{$infix}-end {
      align-self: flex-end !important;
    }
    .align-self#{$infix}-center {
      align-self: center !important;
    }
    .align-self#{$infix}-baseline {
      align-self: baseline !important;
    }
    .align-self#{$infix}-stretch {
      align-self: stretch !important;
    }
  }
}

//
// CUSTOM
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .float#{$infix}-left {
      float: left !important;
    }
    .float#{$infix}-right {
      float: right !important;
    }
  }
}
$pxSizes: () !default;
$pxSizes: map-merge(
  (
    100: 100px,
    250: 250px,
    400: 400px,
    600: 600px
  ),
  $pxSizes
);
@each $size, $length in $pxSizes {
  .min-width-#{$size} {
    min-width: $length;
  }
  .max-width-#{$size} {
    max-width: $length;
  }
}
